<template>
  <b-card :title="`${$t('General.Add')} ${$t('Accounting')} ${$t('Operation')}`">
    <validation-observer ref="simpleRules">
      <b-overlay
        :show="showOverLay"
        rounded="sm"
        :opacity="0.5"
      >
        <b-form ref="formName">
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('General.ArabicName')"
                label-for="arabicName"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.ArabicName')"
                  rules="required"
                >
                  <b-form-input
                    v-model="name_ar"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('General.ArabicName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('General.EnglishName')"
                label-for="englishName"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.EnglishName')"
                  rules="required"
                >
                  <b-form-input
                    v-model="name_en"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('General.EnglishName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :name="`${$t('Document')} ${$t('General.Name')}`"
                rules="required"
              >
                <b-form-group
                  :label="`${$t('Document')} ${$t('General.Name')}`"
                  label-for="documentName"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="document_id"
                    :state="document_id === null ? false : true"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                    :options="document_option"
                    :placeholder="`${$t('Document')} ${$t('General.Name')}`"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false:null"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :name="$t('Branch')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Branch')"
                  label-for="branch"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="branch_id"
                    :state="branch_id === null ? false : true"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                    :options="branch_option"
                    :placeholder="$t('Branch')"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false:null"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('General.Active')"
                label-for="active"
              >
                <b-form-checkbox
                  v-model="active"
                  class="custom-control-primary"
                >
                  {{ $t('General.Active') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="6" />
            <b-col cols="6">
              <!-- alert -->
              <b-alert
                v-height-fade.appear
                variant="danger"
                :show="showError"
                class="text-left p-1"
              >
                <ul class="list-style-icons mb-0">
                  <li><feather-icon icon="ArrowRightIcon" />{{ apiErrors }}</li>
                </ul>
              </b-alert>
            </b-col>
            <b-col cols="6" />
            <b-col cols="12">
              <h4 class="p-1 text-left">
                {{ `${$t('General.Add')} ${$t('Accounting')} ${$t('Operation')} ${$t('Details')}` }}
              </h4>
            </b-col>
            <b-col cols="12">
              <b-form ref="formGrid">
                <b-row class="p-1">
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      :name="`${$t('Account')} ${$t('General.Credit')}`"
                      rules="required"
                    >
                      <b-form-group
                        :label="`${$t('Account')} ${$t('General.Credit')}`"
                        label-for="accountCredit"
                        :state="errors.length > 0 ? false:null"
                      >
                        <v-select
                          v-model="account_credit_id"
                          :state="account_credit_id === null ? false : true"
                          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                          :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                          :options="account_credit_option"
                          :placeholder="`${$t('Account')} ${$t('General.Credit')}`"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false:null"
                        >
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      :name="`${$t('Account')} ${$t('General.Debit')}`"
                      rules="required"
                    >
                      <b-form-group
                        :label="`${$t('Account')} ${$t('General.Debit')}`"
                        label-for="accountDebit"
                        :state="errors.length > 0 ? false:null"
                      >
                        <v-select
                          v-model="account_debit_id"
                          :state="account_debit_id === null ? false : true"
                          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                          :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                          :options="account_debit_option"
                          :placeholder="`${$t('Account')} ${$t('General.Debit')}`"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false:null"
                        >
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Cost Center')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('Cost Center')"
                        label-for="costCenter"
                        :state="errors.length > 0 ? false:null"
                      >
                        <v-select
                          v-model="cost_center_id"
                          :state="cost_center_id === null ? false : true"
                          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                          :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                          :options="cost_center_option"
                          :placeholder="$t('Cost Center')"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false:null"
                        >
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Payment')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('Payment')"
                        label-for="payment"
                        :state="errors.length > 0 ? false:null"
                      >
                        <v-select
                          v-model="payment_id"
                          :state="payment_id === null ? false : true"
                          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                          :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                          :options="payment_option"
                          :placeholder="$t('Payment')"
                        />
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false:null"
                        >
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="5" />
                  <b-col cols="2 text-center p-1 mt-1">
                    <b-button
                      variant="info"
                      type="button"
                      block
                      @click.prevent="addGrid"
                    >
                      {{ $t('General.Add') }}
                    </b-button>
                  </b-col>
                  <b-col cols="5" />
                </b-row>
              </b-form>
            </b-col>
            <b-col cols="3" />
            <b-col cols="12">
              <vue-good-table
                :columns="columns"
                :rows="rows"
                :rtl="direction"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm }"
                :pagination-options="{
                  enabled: true,
                  perPage:pageLength
                }"
                responsive
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >

                  <!-- Column: Action -->
                  <span v-if="props.column.field === 'action'">
                    <span>
                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                          />
                        </template>
                        <b-dropdown-item
                          @click="updateGrid(props.row.id)"
                        >
                          <feather-icon
                            icon="Edit2Icon"
                            class="mr-50"
                          />
                          <span>{{ $t('General.Edit') }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="removeGrid(props.row.id)">
                          <feather-icon
                            icon="TrashIcon"
                            class="mr-50"
                          />
                          <span>{{ $t('General.Delete') }}</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </span>

                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap">
                        {{ $t('Showing 1 to') }}
                      </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['3','5','10','50','100']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap "> {{ $t('of') }} {{ props.total }} {{ $t('General.entries') }} </span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
            <b-col cols="3" />
            <b-col cols="6 text-center p-1">
              <b-button
                variant="primary"
                type="button"
                block
                @click="validationForm"
              >
                {{ $t('General.Save') }}
              </b-button>
            </b-col>
            <b-col cols="3" />
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BOverlay, BAlert, BFormCheckbox,
  BPagination, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightFade } from '@core/directives/animations'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import accountingOperation from '@/service/administration/accounting-operation'
import document from '@/service/administration/document'
import costCenter from '@/service/accounting/cost-center'
import payment from '@/service/administration/payment'
import accountTree from '@/service/accounting/account-tree'
import branch from '@/service/administration/branch'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BAlert,
    BFormCheckbox,
    vSelect,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      name_ar: '',
      name_en: '',
      required,
      document_id: '',
      document_option: [],
      cost_center_id: '',
      cost_center_option: [],
      account_debit_id: '',
      account_debit_option: [],
      account_credit_id: '',
      account_credit_option: [],
      payment_id: '',
      payment_option: [],
      branch_id: '',
      branch_option: [],
      active: true,
      showError: false,
      showOverLay: false,
      apiErrors: [],
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: `${this.$t('Account')} ${this.$t('General.Credit')}`,
          field: store.state.appConfig.layout.isRTL ? 'account_credit.name_ar' : 'account_credit.name_en',
          filterOptions: {
            enabled: true,
            placeholder: `${this.$t('General.Search')} ${this.$t('Account')} ${this.$t('General.Credit')}`,
          },
        },
        {
          label: `${this.$t('Account')} ${this.$t('General.Debit')}`,
          field: store.state.appConfig.layout.isRTL ? 'account_debit.name_ar' : 'account_debit.name_en',
          filterOptions: {
            enabled: true,
            placeholder: `${this.$t('General.Search')} ${this.$t('Account')} ${this.$t('General.Debit')}`,
          },
        },
        {
          label: this.$t('Payment'),
          field: store.state.appConfig.layout.isRTL ? 'payment.name_ar' : 'payment.name_en',
          filterOptions: {
            enabled: true,
            placeholder: `${this.$t('General.Search')} ${this.$t('Payment')}`,
          },
        },
        {
          label: this.$t('Cost Center'),
          field: store.state.appConfig.layout.isRTL ? 'cost_center.name_ar' : 'cost_center.name_en',
          filterOptions: {
            enabled: true,
            placeholder: `${this.$t('General.Search')} ${this.$t('Cost Center')}`,
          },
        },
        {
          label: this.$t('General.Action'),
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      count: 0,
      isUpdate: false,
      vauleUpdate: '',
      locale: this.$i18n.locale,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.layout.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  async created() {
    this.getApiSelect()
  },
  methods: {
    addGrid() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.isUpdate) {
            const addData = {
              id: this.vauleUpdate,
              account_credit: this.account_credit_id,
              account_credit_id: this.account_credit_id.id,
              account_debit: this.account_debit_id,
              account_debit_id: this.account_debit_id.id,
              payment: this.payment_id,
              payment_id: this.payment_id.id,
              cost_center: this.cost_center_id,
              cost_center_id: this.cost_center_id.id,
            }
            const foundIndex = this.rows.findIndex(x => x.id === this.vauleUpdate)
            this.rows[foundIndex] = addData
            this.rows.push({})
            this.rows.pop()
            this.vauleUpdate = ''
            this.isUpdate = false
          } else {
            // eslint-disable-next-line no-plusplus
            this.count++
            const addData = {
              id: this.count,
              account_credit: this.account_credit_id,
              account_credit_id: this.account_credit_id.id,
              account_debit: this.account_debit_id,
              account_debit_id: this.account_debit_id.id,
              payment: this.payment_id,
              payment_id: this.payment_id.id,
              cost_center: this.cost_center_id,
              cost_center_id: this.cost_center_id.id,
            }
            this.rows.push(addData)
          }
          this.clearGrid()
          this.$refs.formGrid.reset()
          // this.postApiProduct()
        }
      })
    },
    async validationForm() {
      await this.fullGrid()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.postApi()
        }
        this.switchLoc()
      })
    },
    async postApi() {
      this.showOverLay = true
      const user = JSON.parse(localStorage.getItem('userData'))
      await accountingOperation.postAxios({
        name_ar: this.name_ar,
        name_en: this.name_en,
        active: this.active ? 1 : 0,
        document_id: this.document_id.id,
        branch_id: this.branch_id.id,
        user_id: user.id,
        accountingOperationDetails: this.rows,
      }).then(() => {
        this.$refs.formName.reset()
        this.showError = false
        this.showToast(this.$t('General.Successfully'), 'CheckCircleIcon', this.$t('General.SavedSuccessfully'), 'success')
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
        this.showToast(this.$t('General.Error'), 'XCircleIcon', this.$t('General.SavedError'), 'danger')
      }).finally(() => {
        this.clearForm()
        this.showOverLay = false
      })
    },
    clearForm() {
      this.name_ar = ''
      this.name_en = ''
      this.active = true
      this.document_id = ''
      this.branch_id = ''
      this.count = 0
      this.rows = []
      this.clearGrid()
    },
    clearGrid() {
      this.account_credit_id = ''
      this.account_debit_id = ''
      this.payment_id = ''
      this.cost_center_id = ''
      this.$refs.simpleRules.reset()
    },
    fullGrid() {
      this.account_credit_id = '.'
      this.account_debit_id = '.'
      this.payment_id = '.'
      this.cost_center_id = '.'
      this.$refs.simpleRules.reset()
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    async getApiSelect() {
      this.showOverLay = true
      await document.getAxios().then(response => {
        this.document_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await costCenter.getAxios().then(response => {
        this.cost_center_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await accountTree.getAxios().then(response => {
        this.account_credit_option = response.data
        this.account_debit_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await payment.getAxios().then(response => {
        this.payment_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await branch.getAxios().then(response => {
        this.branch_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      }).finally(() => { this.showOverLay = false })
    },
    switchLoc() {
      this.locale = this.$i18n.locale
      localize(this.locale)
    },
    updateGrid(id) {
      const updateValue = this.rows.find(x => x.id === id)
      this.clearGrid()
      if (updateValue) {
        this.vauleUpdate = id
        this.account_credit_id = updateValue.account_credit
        this.account_debit_id = updateValue.account_debit
        this.payment_id = updateValue.payment
        this.cost_center_id = updateValue.cost_center
        this.isUpdate = true
      }
    },
    removeGrid(id) {
      this.rows.splice(this.rows.findIndex(a => a.id === id), 1)
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
